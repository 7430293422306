'use strict';

var modal = require('../components/modal');


$('body').on('click', '.hero-poster .terms-link', function (e) {
    e.preventDefault();
    var modalContent = $(this).closest('.footnote').find('.modal-footnote').clone().children();
    modal.getModalHtmlElement('hero-poster-footnote');
    $('#hero-poster-footnote').find('.modal-content').empty();
    $('#hero-poster-footnote').find('.modal-content').html(modalContent);
});

$('body').on('click', '.hero-poster .video-button', function (e) {
    e.preventDefault();
    var modalContent = $(this).closest('.hero-poster').find('.modal').clone().children();
    modal.getModalHtmlElement('hero-poster-modal');
    $('#hero-poster-modal').find('.modal-dialog').empty();
    $('#hero-poster-modal').find('.modal-dialog').html(modalContent);
});

